define('ui/models/token', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    state: Ember.computed('expired', function () {
      if (Ember.get(this, 'expired')) {
        return 'expired';
      }

      return 'active';
    })
  });
});