define('ui/mixins/logging-model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_TARGET_TYPE = 'none';

  exports.default = Ember.Mixin.create({
    // needs to override the type props
    type: null,

    patch: function patch() {
      var t = Ember.get(this, 'targetType');
      var store = Ember.get(this, 'store');

      var nue = store.createRecord({ type: this.get('type') });

      var map = Ember.Object.create({});

      var loggingTagets = ['kafka', 'elasticsearch', 'splunk', 'syslog', 'fluentForwarder'];

      loggingTagets.forEach(function (key) {
        var config = void 0;

        if (key === 'fluentForwarder') {
          config = store.createRecord({ type: 'fluentForwarderConfig' });
        } else {
          config = store.createRecord({ type: key + 'Config' });
        }

        nue.set('config', config);
        Ember.set(map, key, nue.clone());
      });

      this.setProperties(map);
      if (t && t !== 'none') {
        Ember.set(this, t + '.config', Ember.get(this, t + 'Config'));
        Ember.set(this, t + '.outputFlushInterval', Ember.get(this, 'outputFlushInterval'));
        Ember.set(this, t + '.outputTags', Ember.get(this, 'outputTags'));
        Ember.set(this, t + '.dockerRootDir', Ember.get(this, 'dockerRootDir'));
      }

      return this;
    },


    targetType: Ember.computed('elasticsearchConfig', 'splunkConfig', 'kafkaConfig', 'syslogConfig', 'fluentForwarderConfig', function () {
      var es = Ember.get(this, 'elasticsearchConfig');
      var splunk = Ember.get(this, 'splunkConfig');
      var kafka = Ember.get(this, 'kafkaConfig');
      var syslog = Ember.get(this, 'syslogConfig');
      var fluentd = Ember.get(this, 'fluentForwarderConfig');

      if (es) {
        return 'elasticsearch';
      }
      if (splunk) {
        return 'splunk';
      }
      if (syslog) {
        return 'syslog';
      }
      if (kafka) {
        return 'kafka';
      }
      if (fluentd) {
        return 'fluentForwarder';
      }

      return DEFAULT_TARGET_TYPE;
    })
  });
});