define('ui/models/kontainerdriver', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KontainerDriver = _resource.default.extend({
    type: 'kontainerDriver',

    availableActions: Ember.computed('actionLinks.{activate,deactivate}', function () {
      var a = Ember.get(this, 'actionLinks') || {};

      return [{
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        enabled: !!a.activate,
        bulkable: true
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-pause',
        action: 'deactivate',
        enabled: !!a.deactivate,
        bulkable: true
      }];
    }),

    canEdit: Ember.computed('links.update', 'builtin', function () {
      return !!Ember.get(this, 'links.update') && !Ember.get(this, 'builtin');
    }),

    hasUi: Ember.computed('hasBuiltinUi', function () {
      return !!Ember.get(this, 'uiUrl');
    }),

    actions: {
      activate: function activate() {
        return this.doAction('activate');
      },
      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },
      edit: function edit() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-driver', this);
      }
    }

  });

  exports.default = KontainerDriver;
});