define('ui/components/modal-edit-apikey/component', ['exports', 'shared/mixins/new-or-edit', 'shared/mixins/modal-base', 'ui/components/modal-edit-apikey/template', 'moment'], function (exports, _newOrEdit, _modalBase, _template, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    endpointService: Ember.inject.service('endpoint'),
    scope: Ember.inject.service(),

    layout: _template.default,
    classNames: ['large-modal', 'alert'],
    model: null,
    clone: null,
    justCreated: false,
    expire: 'never',

    originalModel: Ember.computed.alias('modalService.modalOpts'),
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'clone', Ember.get(this, 'originalModel').clone());
      Ember.set(this, 'model', Ember.get(this, 'originalModel').clone());
      Ember.set(this, 'justCreated', false);
      this.expireChanged();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      setTimeout(function () {
        _this.$('TEXTAREA')[0].focus();
      }, 250);
    },


    expireChanged: Ember.observer('expire', function () {
      var now = (0, _moment.default)();
      var expire = now.clone();

      if (Ember.get(this, 'expire')) {
        expire = expire.add(1, Ember.get(this, 'expire'));
      }

      Ember.set(this, 'model.ttl', expire.diff(now));
    }),

    displayEndpoint: function () {
      return Ember.get(this, 'endpointService.api.display.current');
    }.property(),

    linkEndpoint: function () {
      return Ember.get(this, 'endpointService.api.auth.current');
    }.property(),

    editing: function () {
      return !!Ember.get(this, 'clone.id');
    }.property('clone.id'),

    displayPassword: Ember.computed('clone.token', 'clone.name', function () {
      var prefix = Ember.get(this, 'clone.name');
      var token = Ember.get(this, 'clone.token');

      if (!token || !prefix) {
        return null;
      }

      var parts = token.split(':');

      if (parts.length === 2 && parts[0] === prefix) {
        return parts[1];
      }

      return null;
    }),

    doneSaving: function doneSaving(neu) {
      if (Ember.get(this, 'editing')) {
        this.send('cancel');
      } else {
        Ember.setProperties(this, {
          justCreated: true,
          clone: neu.clone()
        });
      }
    }
  });
});