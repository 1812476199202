define('ui/verify-auth/route', ['exports', 'shared/utils/constants', 'shared/utils/util', 'ui/mixins/verify-auth'], function (exports, _constants, _util, _verifyAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var samlProviders = ['ping', 'adfs', 'keycloak', 'okta'];
  var allowedForwards = ['localhost'];

  exports.default = Ember.Route.extend(_verifyAuth.default, {
    github: Ember.inject.service(),

    model: function model(params /* , transition */) {
      var _this = this;

      var github = Ember.get(this, 'github');
      var code = Ember.get(params, 'code');
      var forward = Ember.get(params, 'forward');

      // Allow another redirect if the hostname is in the whitelist above.
      // This allows things like sharing github auth between rancher at localhost:8000
      // and rio dev at localhost:8004
      if (forward) {
        var parsed = (0, _util.parseUrl)(forward);

        if (allowedForwards.includes(parsed.hostname.toLowerCase())) {
          if (Ember.get(params, 'login')) {
            window.location.href = (0, _util.addQueryParams)(forward, {
              forwarded: 'true',
              code: code
            });
          } else {
            github.login(forward);
          }
        } else {
          return Ember.RSVP.reject(new Error('Invalid forward url'));
        }

        return;
      }

      if (window.opener && !Ember.get(params, 'login')) {
        var openersGithub = window.opener.ls('github');
        var openerStore = window.opener.ls('globalStore');
        var qp = Ember.get(params, 'config') || Ember.get(params, 'authProvider');
        var type = qp + 'Config';
        var config = openerStore.getById(type, qp);
        var gh = Ember.get(this, 'github');
        var stateMsg = 'Authorization state did not match, please try again.';

        if (Ember.get(params, 'config') === 'github') {
          return gh.testConfig(config).then(function (resp) {
            gh.authorize(resp, openersGithub.get('state'));
          }).catch(function (err) {
            _this.send('gotError', err);
          });
        } else if (samlProviders.includes(Ember.get(params, 'config'))) {
          if (window.opener.window.onAuthTest) {
            reply(null, config);
          } else {
            reply({ err: 'failure' });
          }
        }

        if (Ember.get(params, 'code')) {
          if (openersGithub.stateMatches(Ember.get(params, 'state'))) {
            reply(params.error_description, params.code);
          } else {
            reply(stateMsg);
          }
        }

        if (Ember.get(params, 'oauth_token') && Ember.get(params, 'oauth_verifier')) {
          reply(null, {
            oauthToken: Ember.get(params, 'oauth_token'),
            oauthVerifier: Ember.get(params, 'oauth_verifier')
          });
        }
      }

      if (code && Ember.get(params, 'login')) {
        if (github.stateMatches(Ember.get(params, 'state'))) {
          var ghProvider = Ember.get(this, 'access.providers').findBy('id', 'github');

          return ghProvider.doAction('login', {
            code: code,
            responseType: 'cookie',
            description: _constants.default.SESSION.DESCRIPTION,
            ttl: _constants.default.SESSION.TTL
          }).then(function () {
            return Ember.get(_this, 'access').detect().then(function () {
              return _this.transitionTo('authenticated');
            });
          });
        }
      }

      function reply(err, code) {
        try {
          window.opener.window.onAuthTest(err, code);
          setTimeout(function () {
            window.close();
          }, 250);

          return new Ember.RSVP.promise();
        } catch (e) {
          window.close();
        }
      }
    }
  });
});