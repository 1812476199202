define('ui/models/user', ['exports', '@rancher/ember-api-store/utils/denormalize', '@rancher/ember-api-store/models/resource', 'identicon.js'], function (exports, _denormalize, _resource, _identicon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    growl: Ember.inject.service(),

    globalRoleBindings: (0, _denormalize.hasMany)('id', 'globalRoleBinding', 'userId'),
    clusterRoleBindings: (0, _denormalize.hasMany)('id', 'clusterRoleTemplateBinding', 'userId'),
    projectRoleBindings: (0, _denormalize.hasMany)('id', 'projectRoleTemplateBinding', 'userId'),

    combinedState: Ember.computed('enabled', 'state', function () {
      if (Ember.get(this, 'enabled') === false) {
        return 'inactive';
      } else {
        return Ember.get(this, 'state');
      }
    }),

    displayName: Ember.computed('name', 'username', 'id', function () {
      var name = Ember.get(this, 'name');

      if (name) {
        return name;
      }

      name = Ember.get(this, 'username');
      if (name) {
        return name;
      }

      return '(' + Ember.get(this, 'id') + ')';
    }),

    avatarSrc: Ember.computed('id', function () {
      return 'data:image/png;base64,' + new _identicon.default(AWS.util.crypto.md5(this.get('id') || 'Unknown', 'hex'), 80, 0.01).toString();
    }),

    hasAdmin: Ember.computed('globalRoleBindings.[]', function () {
      return Ember.get(this, 'globalRoleBindings').findBy('globalRole.isAdmin', true);
    }),

    hasCustom: Ember.computed('globalRoleBindings.[]', function () {
      return Ember.get(this, 'globalRoleBindings').findBy('globalRole.isCustom', true);
    }),

    hasUser: Ember.computed('globalRoleBindings.[]', function () {
      return Ember.get(this, 'globalRoleBindings').findBy('globalRole.isUser', true);
    }),

    hasBase: Ember.computed('globalRoleBindings.[]', function () {
      return Ember.get(this, 'globalRoleBindings').findBy('globalRole.isBase', true);
    }),

    isMe: Ember.computed('access.principal', function () {
      return Ember.get(this, 'access.principal.id') === Ember.get(this, 'id');
    }),

    availableActions: Ember.computed('enabled', function () {
      var on = Ember.get(this, 'enabled') !== false;

      return [{
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        bulkable: true,
        enabled: !on
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-pause',
        action: 'deactivate',
        bulkable: true,
        enabled: on
      }];
    }),

    actions: {
      deactivate: function deactivate() {
        var _this = this;

        Ember.run.next(function () {
          Ember.set(_this, 'enabled', false);
          _this.save().catch(function (err) {
            Ember.set(_this, 'enabled', true);
            Ember.get(_this, 'growl').fromError('Error deactivating user', err);
          });
        });
      },
      activate: function activate() {
        var _this2 = this;

        Ember.run.next(function () {
          Ember.set(_this2, 'enabled', true);
          _this2.save().catch(function (err) {
            Ember.set(_this2, 'enabled', false);
            Ember.get(_this2, 'growl').fromError('Error activating user', err);
          });
        });
      },
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('global-admin.accounts.edit', Ember.get(this, 'id'));
      },
      setPassword: function setPassword(password) {
        this.doAction('setpassword', { newPassword: password });
      }
    }

  });
});