define('ui/catalog-tab/launch/route', ['exports', 'shared/utils/util', 'ui/utils/constants'], function (exports, _util, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    modalService: Ember.inject.service('modal'),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterStore: Ember.inject.service(),

    parentRoute: 'catalog-tab',

    model: function model(params /* , transition*/) {
      var _this = this;

      var store = Ember.get(this, 'store');
      var clusterStore = Ember.get(this, 'clusterStore');

      var dependencies = {
        tpl: Ember.get(this, 'catalog').fetchTemplate(params.template),
        namespaces: clusterStore.findAll('namespace')
      };

      if (params.upgrade) {
        dependencies.upgrade = Ember.get(this, 'catalog').fetchTemplate(params.template + '-' + params.upgrade, true);
      }

      if (params.appId) {
        dependencies.app = store.find('app', params.appId);
      }
      if (params.appName) {
        dependencies.app = store.find('app', null, { filter: { name: params.appName } }).then(function (apps) {
          return Ember.get(apps, 'firstObject');
        });
      }

      if (params.namespaceId) {
        dependencies.namespace = clusterStore.find('namespace', params.namespaceId);
      }

      return Ember.RSVP.hash(dependencies, 'Load dependencies').then(function (results) {
        var neuNSN = results.tpl.get('displayName');
        var dupe = results.namespaces.findBy('id', neuNSN);

        if (!results.namespace) {
          var _newNamespace = _this.newNamespace(dupe, neuNSN),
              newNamespaceName = _newNamespace.newNamespaceName,
              newNS = _newNamespace.newNS;

          if (dupe) {
            neuNSN = newNamespaceName;
          }

          results.namespace = newNS;
        }

        var kind = 'helm';
        var neuApp = null;
        var links;

        links = results.tpl.versionLinks;

        var verArr = Object.keys(links).filter(function (key) {
          return !!links[key];
        }).map(function (key) {
          return {
            version: key,
            sortVersion: key,
            link: links[key]
          };
        });

        if (results.app) {
          if (Ember.get(params, 'clone')) {
            var _newNamespace2 = _this.newNamespace(dupe, neuNSN),
                _newNamespaceName = _newNamespace2.newNamespaceName,
                _newNS = _newNamespace2.newNS;

            if (dupe) {
              neuNSN = _newNamespaceName;
            }

            results.namespace = _newNS;

            neuApp = results.app.cloneForNew();
            Ember.set(neuApp, 'name', results.namespace.name);
          } else {
            neuApp = results.app;
          }
        } else {
          neuApp = store.createRecord({
            type: 'app',
            name: results.namespace.name
          });
        }

        if (neuApp.id) {
          verArr.filter(function (ver) {
            return ver.version === Ember.get(neuApp, 'externalIdInfo.version');
          }).forEach(function (ver) {
            Ember.set(ver, 'version', ver.version + ' (current)');
          });
        }

        return Ember.Object.create({
          allTemplates: _this.modelFor(Ember.get(_this, 'parentRoute')).get('catalog'),
          catalogApp: neuApp,
          namespace: results.namespace,
          namespaces: results.namespaces,
          tpl: results.tpl,
          tplKind: kind,
          upgradeTemplate: results.upgrade,
          versionLinks: links,
          versionsArray: verArr
        });
      });
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        Ember.setProperties(controller, {
          appId: null,
          appName: null,
          catalog: null,
          namespaceId: null,
          template: null,
          upgrade: null,
          clone: null
        });
      }
    },
    deactivate: function deactivate() {
      Ember.set(this, 'session.' + _constants.default.SESSION.PROJECT_ROUTE, undefined);
    },


    actions: {
      cancel: function cancel() {
        Ember.get(this, 'modalService').toggleModal();
      }
    },

    newNamespace: function newNamespace(duplicateName, newNamespaceName) {
      var suffix = (0, _util.randomStr)(5, 5, 'novowels');

      if (duplicateName) {
        newNamespaceName = Ember.get(duplicateName, 'displayName') + '-' + suffix;
      }

      var newNS = Ember.get(this, 'clusterStore').createRecord({
        type: 'namespace',
        name: newNamespaceName,
        projectId: this.modelFor('authenticated.project').get('project.id')
      });

      return {
        newNamespaceName: newNamespaceName,
        newNS: newNS
      };
    }
  });
});