define('ui/components/catalog-index/component', ['exports', 'shared/utils/constants', 'ui/components/catalog-index/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    catalog: Ember.inject.service(),
    settings: Ember.inject.service(),
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    search: '',
    parentRoute: null,
    launchRoute: null,
    updating: 'no',

    projectId: Ember.computed.alias('cookies.' + _constants.default.COOKIE.PROJECT),
    categories: Ember.computed.alias('model.categories'),
    catalogs: Ember.computed.union('model.catalogs.globalCatalogs', 'clusterCatalogs', 'projectCatalogs'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.get(this, 'catalog.componentRequestingRefresh');
    },


    actions: {
      clearSearch: function clearSearch() {
        Ember.set(this, 'search', '');
      },
      update: function update() {
        var _this = this;

        Ember.set(this, 'updating', 'yes');
        Ember.get(this, 'catalog').refresh().then(function () {
          Ember.set(_this, 'updating', 'no');
          _this.sendAction('refresh');
        }).catch(function () {
          Ember.set(_this, 'updating', 'error');
        });
      }
    },

    childRequestiongRefresh: Ember.observer('catalog.componentRequestingRefresh', function () {
      if (Ember.get(this, 'catalog.componentRequestingRefresh')) {
        this.send('update');
      }
    }),

    projectCatalogs: Ember.computed('model.catalogs.projecCatalogs', function () {
      var _this2 = this;

      return Ember.get(this, 'model.catalogs.projectCatalogs').filter(function (c) {
        return c.projectId === Ember.get(_this2, 'scope.currentProject.id');
      });
    }),

    clusterCatalogs: Ember.computed('model.catalogs.clusterCatalogs', function () {
      var _this3 = this;

      return Ember.get(this, 'model.catalogs.clusterCatalogs').filter(function (c) {
        return c.clusterId === Ember.get(_this3, 'scope.currentCluster.id');
      });
    }),

    totalCategories: Ember.computed('categoryWithCounts', function () {
      var categories = Ember.get(this, 'categoryWithCounts');
      var count = 0;

      Object.keys(categories).forEach(function (cat) {
        count = count + categories[cat].count;
      });

      return count;
    }),

    categoryWithCounts: Ember.computed('category', 'categories', function () {
      var categories = [];
      var out = {};
      var templates = Ember.get(this, 'catalog.templateCache');

      templates.forEach(function (tpl) {
        if (tpl.categories) {
          tpl.categories.forEach(function (ctgy) {
            categories.push(ctgy);
          });
        }
      });

      categories.sort().forEach(function (ctgy) {
        var normalized = ctgy.underscore();

        if (out[normalized] && ctgy) {
          out[normalized].count++;
        } else {
          out[normalized] = {
            name: ctgy,
            category: normalized,
            count: 1
          };
        }
      });

      var list = [];

      Object.keys(out).forEach(function (key) {
        list.push(out[key]);
      });

      return list;
    }),

    catalogURL: Ember.computed('catalogs', function () {
      var neu = { catalogs: {} };

      Ember.get(this, 'catalogs').forEach(function (cat) {
        neu.catalogs[cat.id] = {
          branch: cat.branch,
          url: cat.url
        };
      });

      return JSON.stringify(neu);
    }),

    filters: Ember.computed('catalogs', function () {
      return Ember.get(this, 'catalogs').filter(function (obj) {
        return Ember.get(obj, 'id') !== 'system-library';
      }).map(function (obj) {
        return {
          catalogId: Ember.get(obj, 'id'),
          label: Ember.get(obj, 'name'),
          scope: Ember.get(obj, 'type')
        };
      });
    }),

    arrangedContent: Ember.computed('model.catalog', 'search', function () {
      var search = Ember.get(this, 'search').toUpperCase();
      var result = [];
      var catalog = Ember.get(this, 'model.catalog');
      var currentProjectId = Ember.get(this, 'scope.currentProject.id');
      var currentClusrerId = Ember.get(this, 'scope.currentCluster.id');

      catalog = catalog.filter(function (item) {
        var projectCatalogId = item.projectCatalogId,
            clusterCatalogId = item.clusterCatalogId;


        if (projectCatalogId && projectCatalogId.split(':').firstObject === currentProjectId.split(':')[1]) {
          return item;
        } else if (clusterCatalogId && clusterCatalogId.split(':').firstObject === currentClusrerId) {
          return item;
        } else if (item.isGlobalCatalog) {
          return item;
        }
      });

      if (!search) {
        return catalog;
      }

      catalog.forEach(function (item) {
        if (item.name.toUpperCase().indexOf(search) >= 0 || item.description.toUpperCase().indexOf(search) >= 0) {
          result.push(item);
        }
      });

      return result;
    })
  });
});