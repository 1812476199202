define('ui/authenticated/cluster/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model: function model() {
      var globalStore = this.get('globalStore');
      var cluster = this.modelFor('authenticated.cluster');

      return Ember.RSVP.hash({
        originalCluster: cluster,
        cluster: cluster.clone(),
        kontainerDrivers: globalStore.findAll('kontainerDriver'),
        nodeTemplates: globalStore.findAll('nodeTemplate'),
        nodeDrivers: globalStore.findAll('nodeDriver'),
        psps: globalStore.findAll('podSecurityPolicyTemplate'),
        roleTemplates: Ember.get(this, 'roleTemplateService').get('allFilteredRoleTemplates'),
        users: globalStore.findAll('user'),
        clusterRoleTemplateBinding: globalStore.findAll('clusterRoleTemplateBinding'),
        me: Ember.get(this, 'access.principal')
      });
    },
    setupController: function setupController(controller /* , model*/) {
      this._super.apply(this, arguments);
      Ember.set(controller, 'step', 1);
    },
    resetController: function resetController(controller, isExisting /* , transition*/) {
      if (isExisting) {
        controller.set('errors', null);
        controller.set('provider', null);
      }
    }
  });
});