define('ui/components/cru-volume-claim-template/component', ['exports', 'shared/utils/parse-unit', 'shared/mixins/view-new-edit', 'shared/mixins/child-hook', 'ui/components/cru-volume-claim-template/template', 'ui/utils/errors'], function (exports, _parseUnit, _viewNewEdit, _childHook, _template, _errors2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),

    layout: _template.default,
    model: null,
    namespace: null,
    persistentVolumes: null,
    storageClasses: null,
    selectNamespace: true,
    actuallySave: true,
    useStorageClass: true,
    capacity: null,
    titleKey: 'cruVolumeClaimTemplate.title',

    canUseStorageClass: Ember.computed.gt('storageClasses.length', 0),

    didReceiveAttrs: function didReceiveAttrs() {
      if (!Ember.get(this, 'persistentVolumes')) {
        Ember.set(this, 'persistentVolumes', Ember.get(this, 'clusterStore').all('persistentVolume'));
      }

      if (!Ember.get(this, 'storageClasses')) {
        Ember.set(this, 'storageClasses', Ember.get(this, 'clusterStore').all('storageClass'));
      }

      if (!Ember.get(this, 'selectNamespace')) {
        Ember.set(this, 'primaryResource.namespaceId', Ember.get(this, 'namespace.id') || Ember.get(this, 'namespace.name'));
      }

      if (Ember.get(this, 'isNew')) {
        var capacity = Ember.get(this, 'primaryResource.resources.requests.storage');

        if (capacity) {
          var bytes = (0, _parseUnit.parseSi)(capacity);
          var gib = bytes / Math.pow(1024, 3);

          Ember.set(this, 'capacity', gib);
        }

        if (!Ember.get(this, 'canUseStorageClass')) {
          Ember.set(this, 'useStorageClass', false);
        }
      } else {
        Ember.set(this, 'capacity', 10);
      }
    },


    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    persistentVolumeChoices: Ember.computed('persistentVolumes.@each.{name,state}', function () {
      return Ember.get(this, 'persistentVolumes').map(function (v) {
        var label = Ember.get(v, 'displayName');
        var state = Ember.get(v, 'state');
        var disabled = state !== 'available';

        if (disabled) {
          label += ' (' + state + ')';
        }

        return {
          label: label,
          disabled: disabled,
          value: Ember.get(v, 'id')
        };
      }).sortBy('label');
    }),

    willSave: function willSave() {
      var _this = this,
          _arguments = arguments;

      var pr = Ember.get(this, 'primaryResource');
      var intl = Ember.get(this, 'intl');

      if (Ember.get(this, 'useStorageClass')) {
        Ember.set(pr, 'volumeId', null);

        var capacity = Ember.get(this, 'capacity');

        if (capacity) {
          Ember.set(pr, 'resources', { requests: { storage: capacity + 'Gi' } });
        } else {
          var errors = [];

          errors.push(intl.t('validation.required', { key: intl.t('cruPersistentVolumeClaim.capacity.label') }));
          Ember.set(this, 'errors', errors);

          return false;
        }
      } else {
        Ember.set(pr, 'storageClassId', null);
        Ember.set(pr, 'resources', { requests: Object.assign({}, Ember.get(pr, 'persistentVolume.capacity')) });
      }

      if (!Ember.get(this, 'actuallySave')) {
        var ok = this._super.apply(this, arguments);

        if (ok) {
          this.sendAction('doSave', { pvc: pr });
          this.doneSaving();
        }

        return false;
      }

      var self = this;
      var sup = this._super;

      if (Ember.get(this, 'selectNamespace')) {
        var _errors = [];

        _errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);

        Ember.set(this, 'errors', _errors);

        if (Ember.get(_errors, 'length') !== 0) {
          return false;
        }

        return this.applyHooks('_beforeSaveHooks').then(function () {
          Ember.set(pr, 'namespaceId', Ember.get(_this, 'namespace.id'));

          return sup.apply.apply(sup, [self].concat(Array.prototype.slice.call(_arguments)));
        }).catch(function (err) {
          Ember.set(_this, 'errors', [_errors2.default.stringify(err)]);
        });
      } else {
        if (!Ember.get(pr, 'namespaceId')) {
          Ember.set(pr, 'namespaceId', '__REPLACE_ME__');
        }

        return sup.apply.apply(sup, [self].concat(Array.prototype.slice.call(arguments)));
      }
    },
    doneSaving: function doneSaving() {
      this.sendAction('done');
    }
  });
});